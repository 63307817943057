.cell {
	cursor: pointer;
	/* width: 100%; */
	height: 100%;
	min-height: 25px;
	padding: 4px;
	text-align: center;
}
.cell:hover {
	padding: 3px;
	border: 1px solid #d9d9d9;
}

.hide {
  display: none;
  padding: 0px;
}

.layout-content {
  height: calc(100% - 50px);
  overflow: auto;
}

.red-button.ant-btn {
  color: #e84749;
  background: #2a1215;
  border-color: #58181c;
}

.red-button.ant-btn:hover {
  color: #e84749;
  background: #130809;
  border-color: #58181c;
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 4px -8px #1890ff !important;
}

.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 4px -8px #1890ff !important;
}

.disabled-hover .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: unset !important;
}

.expandable-row-table table {
  border: 1px solid #1890ff !important;
}

.ant-menu .ant-badge:hover {
  color: #1890ff;
}

.fullpage-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.fullscreen thead th {
  font-size: 22px !important;
  font-weight: bold !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-cell-value-wrap:hover {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.analytic .ant-table-cell {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

td.ant-table-cell.analytic__column.ant-table-cell-row-hover:hover {
  background: #f5d75c;
}

.analytic .ant-table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.collapse-panel-custom .ant-collapse-header-text {
  flex: 1 1 auto !important;
}

.link {
  display: inline-block;
  position: relative;
}

.link:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #40a9ff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.ant-table-row-level-0.yellow {
  background-color: yellow;
}
.ant-table-row-level-0.yellow .ant-table-cell.ant-table-cell-row-hover {
  background-color: #ffff32;
}

.ant-table-row-level-0.yellow .ant-table-cell-fix-left-last {
  background-color: yellow;
}

.ant-table-row-level-0.purple {
  background-color: #c9c;
}
.ant-table-row-level-0.purple .ant-table-cell.ant-table-cell-row-hover {
  background-color: #d1a3d1;
}

.ant-table-row-level-0.purple .ant-table-cell-fix-left-last {
  background-color: #c9c;
}

.ant-table-row-level-0.lightblue {
  background-color: lightblue;
}
.ant-table-row-level-0.lightblue .ant-table-cell.ant-table-cell-row-hover {
  background-color: #b5dbe8;
}

.ant-table-row-level-0.lightblue .ant-table-cell-fix-left-last {
  background-color: lightblue;
}

.ant-table-row-level-0.lightpink {
  background-color: lightpink;
}
.ant-table-row-level-0.lightpink .ant-table-cell.ant-table-cell-row-hover {
  background-color: #ffbdc7;
}

.ant-table-row-level-0.lightpink .ant-table-cell-fix-left-last {
  background-color: lightpink;
}

.ant-table-row-level-0.green {
  background-color: #9c9;
}
.ant-table-row-level-0.green .ant-table-cell.ant-table-cell-row-hover {
  background-color: #a3d1a3;
}

.ant-table-row-level-0.green .ant-table-cell-fix-left-last {
  background-color: #9c9;
}

.ant-table-row-level-0.gold {
  background-color: gold;
}
.ant-table-row-level-0.gold .ant-table-cell.ant-table-cell-row-hover {
  background-color: #f8de4c;
}

.ant-table-row-level-0.gold .ant-table-cell-fix-left-last {
  background-color: gold;
}

.table_registry .ant-table-sticky-scroll-bar {
  height: 26px !important;
  background-color: #130809;
}
.listeners-table .ant-table-sticky-scroll-bar {
  height: 26px !important;
  background-color: #130809;
}
